import logo from '../logo5.svg';
import '../App.css';
import {useNavigate} from 'react-router-dom';
import {useContext, useState} from 'react';
import {FileContext} from '../contexts/Contexts';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {ThemeProvider, createTheme} from '@mui/material/styles';

const theme = createTheme({
	palette:{
		primary: {
			main:'#ff00ff',
		},
		secondary:{
			main:'#ff0000',
		}, 
	},
});

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


const Home = () => {
	//rFile: result(table) file
	const {rFile, setRFile, user, setUser, table, setTable, slots_cors, setSlots_cors, corsindex, setCorsindex, corstds, setCorstds, stdsindex, setStdsindex} = useContext(FileContext);
	let navigate = useNavigate();
	//result(table) file change handler
	async function rFileChangeHandler(e){
		setTable(document.createElement('table'));
		setSlots_cors([]);
		setRFile(e.target.files[0]);
		navigate('/table');
	}
	//input(raw data) file change handler
	const [selectedFile, setSelectedFile] = useState(null);
	const fileChangeHandler = (e) => {
		setTable(document.createElement('table'));
		setSlots_cors([]);
		setSelectedFile(e.target.files[0]);
		console.log(e.target.files[0]);
	}
	const handleSubmit = async (e) => {
		e.preventDefault();
		console.log(user);
		const formData = new FormData();
		formData.append(
		  "file",
		  selectedFile,
		  selectedFile.name
		);
		const requestOptions = {
		  method:'POST',
		  body: formData
		};
		await fetch("https://tyrano.daalgo.org/file/upload_download/", requestOptions)
		//await fetch("http://127.0.0.1:8000/file/upload_download/", requestOptions)
		.then(res => res.blob())
		.then(data => {
		  //blob and excel file can be processed with same method
		  setTable(document.createElement('table'));
		  setRFile(data);
		  setSlots_cors([]);
		  setCorsindex(new Map());
		  setCorstds(new Map());
		  setStdsindex(new Map());
		  navigate('/table');
		  //download the result file
		  console.log("check data before");
		  console.log(data)
		  var a = document.createElement('a');
		  var url = window.URL.createObjectURL(data);
		  a.href = url;
		  a.download = "result2024.xlsx";
		  document.body.appendChild(a);
		  a.click();
		  window.URL.revokeObjectURL(url);
		  a.parentNode.removeChild(a);
		  console.log("check data after");
		  console.log(data);
		});//end of await
	}
	return (

	<div style = {{display:"flex", flexDirection:"column", justifyContent:"center", width:"100%", textAlign:"center", alignItems:"stretch"}}>
	  <div style = {{flex:1, height:"auto", backgroundColor:"tomato", color:"white", fontFamily:"Times New Roman", marginTop:0, marginBottom:0, paddinTop:10, paddingBottom:10}}>
	    <img src={logo} style={{marginTop:30, marginBottom:10}} className="App-img" alt="logo" />
	    <h1 style = {{flex:1, marginTop:30, marginBottom:20, paddinTop:10, paddingBottom:10, fontFamily:"Times New Roman", fontWeight:"bold", fontSize:50, textAlign:"center", width:"100%", alignItems:"stretch"}} className="App-text"> Tyrano Scheduler </h1>
	  </div >
	  <div  style = {{flex:1, height:"auto", backgroundColor:"dodgerblue", color:"black", textAlign:"center", marginTop:0, marginBottom:0, paddinTop:0, paddingBottom:0}} >
	    <ThemeProvider theme = {theme}>
	    <div className="App-m">
		    <Button
		      component="label"
		      role={undefined}
		      variant="contained"
		      tabIndex={-1}
		      startIcon={<CloudUploadIcon />}
		      style = {{marginTop:30, marginBottom:20}}
		      color = "primary"
		    >
		      Upload file
		      <VisuallyHiddenInput onChange = {fileChangeHandler} name = "xlsxfile" type = "file" accept = ".xlsx" />
		    </Button>
		    {
		        Object.keys(user).length != 0 &&
		        <Button variant="contained" size="large" onClick = {handleSubmit} style = {{marginTop:10, marginBottom:20}}>
          			Generate Timetable
        		</Button>
	      	}
	    </div>
	    </ThemeProvider>
	  </div>
	  <div style = {{flex:1, height:"auto", textAlign:"center", backgroundColor:"mediumseagreen", paddinTop:10, paddingBottom:10}} >
	  	<div className="App-footer">
	  		<h1 style = {{flex:1, marginTop:110, marginBottom:0, paddinTop:10, paddingBottom:10, fontFamily:"Times New Roman", fontWeight:"bold", textAlign:"center", widht:"100%", alignItems:"stretch"}}> by DaAlgo </h1>
	  	</div>
	  </div>
	</div>
	);
};
export default Home;