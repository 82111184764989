import React, {useState, useEffect} from 'react';
import Table from './components/Table';
import Home from './components/Home';
import Records from './components/Records';
import NavbarComponent from "./components/NavbarComponent";
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {FileContext} from './contexts/Contexts';
import {KeyContext} from './contexts/Contexts';

function App() {
  //rFile: result(table) file
  const [rFile, setRFile] = useState(null);
  const [key, setKey] = useState("");
  const [user, setUser] = useState({});
  const [table, setTable] = useState(null);
  const [title, setTitle] = useState("");
  const [slots_cors, setSlots_cors] = useState([]);
  const [corstds, setCorstds] =  useState(new Map());
  const [corsindex, setCorsindex] = useState(new Map());
  const [stdsindex, setStdsindex] = useState(new Map());
  const [uid, setUid] = useState(null);

  useEffect(()=>{
    setTable(document.createElement('table'));
  },[]);

  useEffect(()=>{
    console.log("user login");
    console.log("user "+user);
  },[user]);

  const router = createBrowserRouter([
    {element: <KeyContext.Provider value={{key, setKey, user, setUser, uid, setUid}}><NavbarComponent/></KeyContext.Provider>,
     children: [
     {
      path:"/",
      element: <FileContext.Provider value={{rFile, setRFile, user, setUser, table, setTable, slots_cors, setSlots_cors, corsindex, setCorsindex, corstds, setCorstds, stdsindex, setStdsindex}}><Home/></FileContext.Provider>
     },
     {
      path:"/table",
      element: <FileContext.Provider value={{rFile, setRFile, table, setTable, title, setTitle, slots_cors, setSlots_cors, corsindex, setCorsindex, corstds, setCorstds, stdsindex, setStdsindex, uid, setUid}}><Table/></FileContext.Provider>
     },
     {
      path:"/exam",
      element: <FileContext.Provider value={{rFile, setRFile, user, setUser, setSlots_cors, setCorsindex, setCorstds, setStdsindex, table, setTable}}><Records/></FileContext.Provider>
     },
      ]
    }
    ]);


  return (
    <>
      
      <RouterProvider router = {router}/>
    </>
  );
}


export default App;
