import React, {useState, useEffect, useContext} from "react";
import {useNavigate} from 'react-router-dom';
import {FileContext} from '../contexts/Contexts';
import {Button} from "@mui/material";
import {Table, TableBody, TableRow, TableCell} from "@mui/material";
function Records() {
  const {rFile, setRFile, user, setUser, setSlots_cors, setCorsindex, setCorstds, setStdsindex, table, setTable} = useContext(FileContext);
  const [records, setRecords] = useState([]);
  const [record, setRecord] = useState({id:7, name:"aaa", email:"aaa@gmail.com", password:"2222"});
  let navigate = useNavigate();

  const fetchRecords = async () => {
    console.log("entering fetching records");
    console.log(user);
    const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type":"application/json"
        }
    };
    await fetch(`https://tyrano.daalgo.org/fetch_tables/${user.email}`, requestOptions)
    //await fetch(`http://127.0.0.1:8000/fetch_tables/${user.email}`, requestOptions)
    .then(
      (response) => response.json()
    )
    .then((data) => {
      console.log("inside fetching records");
      console.log(data);
      setRecords(data);
    })
  }

  const fetchRecord = async (id) => {
    const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type":"application/json"
        }
    };
    await fetch(`https://tyrano.daalgo.org/${id}`, requestOptions)
    //await fetch(`http://localhost:8000/${id}`)
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      setRecord(data);
    })
  }

  const createRecord = async () => {
    const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type":"application/json"
        },
        body: JSON.stringify(record)
    };
    await fetch(`https://tyrano.daalgo.org`, requestOptions)
    //await fetch('http://localhost:8000',requestOptions)
          .then((response) => response.json())
          .then((data) =>{
            console.log(data);
            setRecords(data);
          })
  }

  const updateRecord = async () => {
    const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type":"application/json"
        },
        body: JSON.stringify(record)
    };
    await fetch(`https://tyrano.daalgo.org`, requestOptions)
    //await fetch('http://localhost:8000', requestOptions)
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            setRecords(data);
    });

  }

  const deleteRecord = async (rid, email) => {
    const requestOptions = {
      method: "DELETE",
      headers:{
        "Content-Type": "application/json"
      },
      body:JSON.stringify({
        rid: rid,
        email: email
      })
    };
    await fetch(`https://tyrano.daalgo.org/deleteRecord`, requestOptions)
    //await fetch(`http://127.0.0.1:8000/deleteRecord`, requestOptions)
          .then(
            (response) => response.json()
          )
          .then((data) => {
            console.log("result of deleting records");
            console.log(data);
            setRecords(data);
          });
  }

  const showTable = async (fourl) => {
    const requestOptions = {
      method: "GET",
      headers:{
        "Content-Type": "application/json"
      }
    };
    await fetch(`https://tyrano.daalgo.org/file/downloadFromS3/${fourl}`, requestOptions)
    //await fetch(`http://127.0.0.1:8000/file/downloadFromS3/${fourl}`, requestOptions)
      .then((res) => res.blob())
      .then((data) => {
        setTable(document.createElement('table'));
        setRFile(data);
        setSlots_cors([]);
        setCorsindex(new Map());
        setCorstds(new Map());
        setStdsindex(new Map());
        navigate('/table');
      });
  }

  useEffect(() => {fetchRecords();},[]);

  return (
    <div>
     <Table arial-label = "simple table">
        <TableBody>
          {records.map((row) => (
            <TableRow key = {row.rid}>
              <TableCell>
                {row.rid}
              </TableCell>
              <TableCell>
                {row.uid}
              </TableCell>
              <TableCell>
                {row.fo}
              </TableCell>
              <TableCell>
                {row.fourl}
              </TableCell>
              <TableCell>
                <Button onClick = {() => showTable(row.fourl)} variant = "contained" color = "primary">
                  Show
                </Button>
              </TableCell>
              <TableCell>
                <Button onClick = {() => deleteRecord(row.rid, user.email)} variant = "contained" color = "secondary">
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export default Records;
