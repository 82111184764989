import readXlsxFile from 'read-excel-file';
import writeXlsxFile from 'write-excel-file';
import React, {useState} from 'react';
import {useEffect} from 'react';
import {useContext} from 'react';
import {FileContext} from '../contexts/Contexts';
import TableComponent from './TableComponent';
function Table() {
  const {rFile, setRFile} = useContext(FileContext);
  console.log("check in table");
  console.log(rFile);
  useEffect(()=>{
    console.log("Table Mounted");
    return () => {
      console.log("Table UnMounted");
    };
  },[]);
  return (
    <>
    {rFile && <TableComponent/>}
    </>
  );
}

export default Table;
